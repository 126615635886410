const NavData = [
  // {
  //   name: 'Solutions',
  //   url: '',
  // },
  // {
  //   name: 'Case Studies',
  //   url: '',
  // },
  // {
  //   name: 'Integrations',
  //   url: '',
  // },
  // {
  //   name: 'Help',
  //   url: 'https://docs.openlaw.io',
  //   divider: true,
  // },
  {
    name: 'Docs',
    url: 'https://docs.openlaw.io',
  },
];

export default NavData;
